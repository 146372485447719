import { debounce, once } from 'lodash';
import uri from '@wix/communities-forum-client-commons/dist/src/services/urijs';
import { createStore, applyMiddleware, bindActionCreators } from 'redux';
// import { composeWithDevTools } from 'remote-redux-devtools';
import { enableBatching } from 'redux-batched-actions';
import thunk from 'redux-thunk';
import { SANTA_MEMBERS_APP_ID, CHAT_APP_DEF_ID } from '../constants/applications';
import {
  CREATE_COMMENT,
  CREATE_POST,
  DELETE_COMMENT,
  DELETE_POST,
  LIKE_COMMENT,
  LIKE_POST,
  UPDATE_COMMENT,
  UPDATE_POST,
} from '../constants/interactions';
import { DELETE_POST_SUCCESS } from '../actions/delete-post';
import { DELETE_COMMENT_SUCCESS } from '../actions/delete-comment';
import createRequest from '@wix/communities-forum-client-commons/dist/src/services/create-request';
import { fetchAllCategories, fetchSubcategories } from '../../common/actions/fetch-categories';
import { addBestAnswerAndNotify } from '../actions/add-best-answer';
import { removeBestAnswerAndNotify } from '../actions/remove-best-answer';
import { startEditingComment } from '../actions/start-editing-comment';
import { fetchCategoryPosts } from '../actions/fetch-category-posts';
import { setPostTypeFilter } from '../actions/set-filter';
import { resetPostTypeFilter } from '../actions/reset-filter';
import { subscribeToCategory } from '../actions/subscribe-to-category';
import { unsubscribeFromCategory } from '../actions/unsubscribe-from-category';
import { resolveCommentDeepLinkUrlPromisified } from '../actions/comment-deep-link-actions';
import { fetchForumData } from '../actions/fetch-forum-data';
import { fetchLocalStorage } from '../actions/fetch-local-storage';
import { setLocalStorageItem } from '../actions/set-local-storage-item';
import { subscribeToChange } from '../../common/services/state-optimizer';
import { showJoinCommunityPopup } from '../../common/actions/join-community';
import { pageOpened } from '../actions/page-opened';
import {
  isMaInstalled,
  setCommunitiesContext,
  initIsMembersChatEnabled,
} from '../../common/store/communities-context/communities-context-actions';
import { setBasicParams } from '../../common/store/basic-params/basic-params-actions';
import { setStyleParams } from '../../common/store/style-params/style-params-actions';
import { fetchExperiments } from '../../common/actions/fetch-experiments';
import {
  createSentryMonitor,
  decorateActionsWithLogger,
  ENVIRONMENT,
} from '../../common/services/monitoring';
import { isRtlLanguage } from '../../common/services/is-rtl-language';
import { fetchUser } from '../../common/actions/fetch-user';
import { fetchTranslations } from '../../common/store/translations/translations-actions';
import {
  createDuplexerConnection,
  createDuplexerMiddleware,
  socketEventHandlers,
} from '../middleware/duplexer-middleware';
import noopMiddleware from '../middleware/noop-middleware';
import createLocalStorageMiddleware from '../middleware/local-storage-middleware';
import rootReducer from '../reducers';
import {
  createModalClosedMiddleware,
  createModalOpenedMiddleware,
  modalActions,
} from '../../common/modals/framework/initialize-store';
import { modalResolverMapByType } from '../components/modals/modal-resolver-map-by-type';
import { modalDataLoaderByType } from '../components/modals/modal-data-loader-map-by-type';
import { setRouterMatch } from '../../common/router';
import { transformWixCodeLocation } from '../../common/store/location/transform-wix-code-location';
import { createRouter } from './create-router';
import search, { clearSearchResults } from '../actions/search';
import {
  incrementCommentLikeCount,
  INCREMENT_COMMENT_LIKE_COUNT_SUCCESS,
} from '../actions/increment-comment-like-count';
import {
  incrementPostLikeCount,
  INCREMENT_POST_LIKE_COUNT_SUCCESS,
} from '../../common/actions/increment-post-like-count';
import {
  messageActions,
  createMessageMiddleware,
} from '../../common/messages/framework/initialize-store';
import messageResolvers from '../components/messages/message-resolvers';
import { navigateWithinForum } from '../../common/actions/navigate-within-forum';
import {
  categoriesManagerApi,
  categoriesManagerPersistCategoryPromisified,
} from '../actions/categories-manager-actions';
import createShowMessage from '../../common/messages/framework/create-show-message';
import {
  uploadImageCategoriesManager,
  uploadImageCategoriesManagerPromisified,
  uploadImageRCEPromisified,
} from '../actions/image-upload/upload-image';
import { fetchPostPageData } from '../actions/fetch-post-page-data';
import { incrementPostViewCount } from '../actions/increment-post-view-count';
import { setPostsPageSorting, setSearchPageSorting } from '../actions/set-sorting';
import { setIsLoaded } from '../actions/set-is-loaded';
import { setIsLoading } from '../actions/set-is-loading';
import { subscribeToPost } from '../actions/subscribe-to-post';
import { unsubscribeFromPost } from '../actions/unsubscribe-from-post';
import { pinPost } from '../actions/pin-post';
import { unpinPost } from '../actions/unpin-post';
import { enablePostComments } from '../actions/enable-post-comments';
import { disablePostComments } from '../actions/disable-post-comments';
import { actionsOpened } from '../actions/actions-opened';
import { actionsClosed } from '../actions/actions-closed';
import { buttonClicked } from '../actions/button-clicked';
import { emitOpenCategory, emitCloseCategory } from '../actions/category-socket';
import { emitForumOpened } from '../actions/forum-socket';
import { emitTypingThrottled, clearTypingUsers } from '../actions/typing-socket';
import { emitClosePost, emitOpenPost } from '../actions/post-socket';
import { removeItemFromUploadedRegistry } from '../actions/uploaded-registry-actions';
import { fetchOembed } from '../actions/fetch-oembed';
import { appLoaded } from '../actions/app-loaded';
import { createPostAndNavigateToItPromisified, CREATE_POST_SUCCESS } from '../actions/create-post';
import { updatePostPromisified, UPDATE_POST_SUCCESS } from '../actions/update-post';
import {
  createComment,
  createCommentReply,
  createCommentPromisified,
  createCommentReplyPromisified,
  CREATE_COMMENT_SUCCESS,
} from '../actions/create-comment';
import {
  fetchReplies,
  fetchInitialReplies,
  fetchRepliesToPagePromisified,
  fetchRepliesPromisified,
} from '../actions/fetch-replies';
import { updateCommentPromisified, UPDATE_COMMENT_SUCCESS } from '../actions/update-comment';
import { stopEditingComment } from '../actions/stop-editing-comment';
import { fetchCommentsWithReplies } from '../actions/fetch-comments';
import { upvoteComment, downvoteComment, unvoteComment } from '../actions/vote-comment';
import { changePage } from '../actions/change-page';
import { goBack } from '../actions/go-back';
import { clearComments } from '../actions/clear-comments';
import { initializeLocation } from '../../common/store/location/location-actions';
import { initializeMaNavigation } from '../../common/store/ma-navigation/ma-navigation-actions';
import { getCurrentUser } from '../../common/store/current-user/current-user-selectors';
import { parseInstance } from '@wix/communities-forum-client-commons/dist/src/services/parse-instance';
import { addErrorState, addDebugState } from '../../common/store/debug-state/debug-state-actions';
import { handleError } from '../../common/store/debug-state/handle-error';
import createInteractionsMiddleware from '../../common/interactions/create-interactions-middleware';
import { initialiseInstanceValues } from '../../common/store/instance-values/instance-values-actions';
import { initBiMiddleware } from '../bi-events/init-middleware';
import { FORUM_SECTION_ID } from '@wix/communities-forum-universal/dist/src/constants/appsConfig';
import listenToSettingsChange, { applySettingsChange } from '../services/listen-to-settings-change';
import createRouterMiddleware from '../middleware/create-router-middleware';
import { handleProvisioning } from '../../common/services/handle-provisioning';
import { fastFormSubmitCallback } from '../../common/components/fast-form/store/fast-form-actions';
import { createFastFormSubmitMiddleware } from '../../common/components/fast-form/store/fast-form-submit-middleware';
import { setHasReportedAppLoaded } from '../../common/store/app-loaded/app-loaded-actions';
import { validateLanguage } from '../../common/services/validate-language';
import { fetchBadges } from '../actions/fetch-badges';
import { fetchPaidPlans } from '../actions/fetch-paid-plans';
import {
  requestCredentialsPromisified,
  requestCredentialsV2Promisified,
  requestFileDownloadUrlPromisified,
} from '../../common/services/wix-media/media-api';
import { fileDownloadRequest, fileUploaded } from '../actions/file-upload-bi-actions';
import {
  userEventsMakeCategoryChildOrParent,
  userEventsShowEmptyState,
  userEventsReplyOrCommentIntent,
  userEventsClickPublish,
  userEventsClickLike,
  userEventsClickProfileLink,
  userEventsHitUploadSizeLimit,
  userEventsClickCreatePost,
  userEventsClickCreatePostButton,
} from '../actions/user-events';
import { requestLoginPromisified, requestLogin } from '../actions/request-login';
import { setSavedAction } from '../../common/store/saved-action/set-saved-action';
import { invokeSavedAction } from '../services/invoke-saved-action';
import { getLocation } from '../../common/store/location/location-selectors';
import {
  manageCategoryMembersAddSiteMembers,
  manageCategoryMembersRemoveSiteMembers,
} from '../actions/manage-category-members';
import { categoryAddGroups, categoryRemoveGroups } from '../actions/private-category-actions';
import {
  fetchSiteMembersList,
  resetSiteMembersList,
} from '../../common/store/site-members/site-members-actions';
import { assignMembersToGroup } from '../actions/assign-members-to-group';
import { deleteMembersFromGroup } from '../actions/delete-members-from-group';
import {
  fetchMembersGroupsList,
  fetchMembersGroupsListPromisified,
} from '../actions/fetch-members-groups-list';
import BiLocationTracker from '../bi-events/bi-location-tracker';
import { triggerOfflineMigration } from '../actions/offline-migration-actions';
import createRefreshAfterOnlineMigrationMiddleware from '../middleware/create-refresh-after-offline-migration-middleware';
import { fetchCategoriesGroups } from '../../common/actions/fetch-categories-groups';
import globalLoaderMiddleware from '../middleware/global-loader-middleware';
import * as forumsUouEvents from '../bi-events/forums-uou-events';
import * as forumsUsersEvents from '../bi-events/forums-users-events';
import { navigateToPaidPlans } from '../actions/navigate-to-paid-plans';
import { initDevToolsStore } from '../components/dev-tools/dev-tools-store';
import { fetchLikesDataPromisified, resetLikesData } from '../actions/who-liked-actions';
import { fetchSimilarPosts } from '../actions/fetch-similar-posts';
import { fetchExternalLinks } from '../actions/fetch-external-links';
import { fetchMembersGroupsAndPaidPlans } from '../actions/fetch-members-groups-and-paid-plans';
import { waitForSuccessfulResponsePromisified } from '../../common/actions/wait-for-successful-response';
import { showActivateSubscriptionMessageIfNeeded } from '../actions/show-activate-subscriptions-message';
import { isSSR } from '../../common/store/basic-params/basic-params-selectors';
import {
  onBeforePostCreateHook,
  onBeforePostUpdateHook,
  onFetchExternalLinksHook,
  onBeforeCommentCreateHook,
  onBeforeCommentUpdateHook,
} from './forum-external-hooks';
import { fetchComment } from '../actions/fetch-comment';
import { markPostsAsRead } from '../actions/activities-actions';
import { FORUM_APP_ID } from '@wix/communities-forum-universal/dist/src/constants/appsConfig.prod';
import { getCurrentInstance } from '../../common/services/get-instance';
import { createThrottleMiddleware } from '../../common/store/throttle-middleware/throttle-middleware';
import forumDisplayStateMiddleware from '../middleware/forum-display-state-middleware';
import { loadRCE } from '../actions/load-rce';
import { getExperimentValue } from '../selectors/experiments-selectors';
import { EXPERIMENT_RCE_LOADING_TYPE } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import { setCustomRoutes } from '../../common/store/custom-routes/custom-routes-actions';
import initMiddlewareForExperiment from '../services/init-middleware-for-experiment';
import { subscribeToAllCategories } from '../actions/subscribe-to-all-categories';
import { unsubscribeFromAllCategories } from '../actions/unsubscribe-from-all-categories';
import { getStaticsVersionFromUrl } from '../../common/services/statics-version';

const isProduction = process.env.NODE_ENV === 'production';

export const createForumAppController = (
  { appParams, compId, config, setProps, wixCodeApi, platformAPIs, type },
  allCtrls,
) => {
  const { fedOpsLoggerFactory } = platformAPIs;
  const isEditor = wixCodeApi.window.viewMode === 'Editor';
  const isPreview = wixCodeApi.window.viewMode === 'Preview';
  const isSite = wixCodeApi.window.viewMode === 'Site';
  const isSSR = wixCodeApi.window.rendering.env === 'backend';
  const isDebug = wixCodeApi.location.query.debug;
  const devToolsStore = initDevToolsStore(platformAPIs);
  const sentry = createSentryMonitor({
    platformAPIs,
    isSSR,
    environment: ENVIRONMENT.APP,
    appParams,
  });

  const fedopsLogger = fedOpsLoggerFactory.getLoggerForWidget({
    appId: appParams.appDefinitionId,
    widgetId: type,
  });
  fedopsLogger.appLoadStarted();
  const fedopsAppLoaded = once(() => fedopsLogger.appLoaded());

  const initialLoadPromises = [];
  const language = devToolsStore.state.language || validateLanguage(wixCodeApi.site.language);

  let onSettingsChange;

  const log = createLogger(isDebug, isProduction);

  log('createForumAppController', {
    appParams,
    allCtrls,
    wixCodeApi,
    isSSR,
    language,
  });

  const pageReady = () => {
    log('createForumAppController.pageReady -> start');

    return Promise.resolve(
      wixCodeApi.site.getSectionUrl({ sectionId: FORUM_SECTION_ID, appDefinitionId: FORUM_APP_ID }),
    )
      .then(({ url: sectionUrl }) => {
        let router;
        const getRouter = () => router;
        const store = createReduxStore({
          appParams,
          wixCodeApi,
          compId,
          reducers: rootReducer,
          isDebug,
          fedopsLogger,
          getRouter,
          platformAPIs,
          language,
          isSSR,
          sentry,
          isSite,
          config,
        });
        const actions = initializeActions({
          wixCodeApi,
          store,
          fedopsLogger,
          fedopsAppLoaded,
          BiLocationTracker,
          sentry,
        });
        const actionsPromisified = initializePromisifiedActions({
          wixCodeApi,
          compId,
          store,
          sentry,
        });
        let location;
        const locationPromise = transformWixCodeLocation(wixCodeApi, sectionUrl).then(
          l => (location = l),
        );
        initialLoadPromises.push(locationPromise);
        router = createRouter(store, config, wixCodeApi, compId);
        store.dispatch(initializeLocation());
        onSettingsChange = applySettingsChange(store, () => doRouting({ store, router, location }));

        initialLoadPromises.push(
          initializeStoreBaseData({
            wixCodeApi,
            store,
            language,
            platformAPIs,
            config,
          }),
        );

        if (wixCodeApi.location.onChange) {
          wixCodeApi.location.onChange(async ({ path }) => {
            log('navigated to new path', path);
            store.dispatch(initializeLocation());
            const location = await transformWixCodeLocation(wixCodeApi, sectionUrl, path);
            doRouting({
              store,
              router,
              location,
              isInitialLoad: false,
            });
          });
        }

        return Promise.all(initialLoadPromises)
          .then(() => {
            const promise = doRouting({ store, router, location, isInitialLoad: true });
            return promise;
          })
          .then(() => {
            log('createForumAppController.pageReady -> done');
            const state = store.getState();

            // after initial routing is done, we subscribe to get routing change as fast as route changes, without waiting for handler to resolve
            router.onMatch(match => store.dispatch(setRouterMatch(match)));

            setProps({
              ...state,
              actions,
              actionsPromisified,
              cssBaseUrl: appParams.baseUrls.staticsBaseUrl,
              sentryRelease: getStaticsVersionFromUrl(appParams.baseUrls.staticsBaseUrl),
              isRTL: isRtlLanguage(language),
              isSSR,
              devToolsStore,
            });
            if (getExperimentValue(store.getState(), EXPERIMENT_RCE_LOADING_TYPE) === 'onFocus') {
              setTimeout(() => store.dispatch(loadRCE()), 15000);
            }

            if (isSSR) {
              fedopsAppLoaded();
            }

            if (isEditor || isPreview) {
              listenToSettingsChange(store, () => doRouting({ store, router, location }));
            }

            if (isEditor || isPreview) {
              initializeNavigationHandlerForEditor({ store, router });
            }

            refreshDataOnLogin({ wixCodeApi, store, router });
            subscribeToChange(store, partialState => setProps({ ...partialState }));
            devToolsStore.onChange(latestDevToolsStore =>
              setProps({ devToolsStore: latestDevToolsStore }),
            );
          })
          .catch(
            handleError({
              store,
              actions,
              actionsPromisified,
              setProps,
              appParams,
              sentry,
              isSSR,
            }),
          );
      })
      .catch(
        handleError({
          setProps,
          appParams,
          sentry,
          isSSR,
        }),
      );
  };

  return Promise.resolve({
    pageReady: () => {
      if (wixCodeApi.window.viewMode === 'Editor') {
        return handleProvisioning(appParams, fedopsLogger, wixCodeApi, setProps, pageReady);
      }
      return pageReady();
    },
    updateConfig: ($w, config) => {
      console.log('[APP] updateConfig', config);
      onSettingsChange(config);
    },
    exports: () => ({
      onBeforePostCreate: onBeforePostCreateHook.setHook,
      onBeforePostUpdate: onBeforePostUpdateHook.setHook,
      onBeforeCommentCreate: onBeforeCommentCreateHook.setHook,
      onBeforeCommentUpdateHook: onBeforeCommentUpdateHook.setHook,
      onBeforeCommentUpdate: onBeforeCommentUpdateHook.setHook,
      onFetchExternalLinks: onFetchExternalLinksHook.setHook,
    }),
  }).catch(console.error);
};

function createReduxStore({
  reducers,
  appParams,
  wixCodeApi,
  compId,
  isDebug,
  fedopsLogger,
  platformAPIs,
  getRouter,
  language,
  isSSR,
  sentry,
  isSite,
  config,
}) {
  const p = {};
  const getInstance = () => getCurrentInstance(wixCodeApi);

  function createBiMiddleware(events) {
    return initBiMiddleware(
      platformAPIs,
      events,
      isSite,
      wixCodeApi,
      appParams.instanceId,
      config,
      BiLocationTracker,
      events === forumsUsersEvents,
    );
  }

  function createRequestWithBaseUrl(baseUrl) {
    return createRequest({
      baseUrl,
      getInstance,
      locale: language,
      trackError: error => p.store.dispatch(addErrorState(error)),
      logResponse: isDebug ? response => p.store.dispatch(addDebugState(response)) : undefined,
      petriOvr: wixCodeApi.location.query.petri_ovr,
      siteRevision: wixCodeApi.site.revision,
    });
  }

  const isEditor = wixCodeApi.window.viewMode === 'Editor';
  const isPreview = wixCodeApi.window.viewMode === 'Preview';

  const origin = uri(wixCodeApi.location.baseUrl).origin();
  const baseUrls = appParams.baseUrls;

  const baseUrl =
    wixCodeApi.window.rendering.env === 'backend'
      ? `${origin}${baseUrls.apiBaseUrlClient}`
      : baseUrls.apiBaseUrlClient;
  const badgesApiBaseUrl =
    wixCodeApi.window.rendering.env === 'backend'
      ? `${origin}${baseUrls.apiBadgesBaseUrlClient}`
      : baseUrls.apiBadgesBaseUrlClient;
  const membersGroupsApiBaseUrl =
    wixCodeApi.window.rendering.env === 'backend'
      ? `${origin}${baseUrls.apiMembersGroupsBaseUrlClient}`
      : baseUrls.apiMembersGroupsBaseUrlClient;
  const siteMembersApiBaseUrl =
    wixCodeApi.window.rendering.env === 'backend'
      ? `${origin}${baseUrls.apiSiteMembersBaseUrlClient}`
      : baseUrls.apiSiteMembersBaseUrlClient;
  const paidPlansApiBaseUrl =
    wixCodeApi.window.rendering.env === 'backend'
      ? `${origin}${baseUrls.apiPaidPlansBaseUrlClient}`
      : baseUrls.apiPaidPlansBaseUrlClient;
  const request = createRequestWithBaseUrl(baseUrl);
  const badgesRequest = createRequestWithBaseUrl(badgesApiBaseUrl);
  const oembedRequest = createRequestWithBaseUrl(baseUrls.oembedBaseUrlClient);
  const oembedRequestRCE = createRequestWithBaseUrl('/rich-content/oembed');
  const membersGroupsRequest = createRequestWithBaseUrl(membersGroupsApiBaseUrl);
  const siteMembersRequest = createRequestWithBaseUrl(siteMembersApiBaseUrl);
  const paidPlansRequest = createRequestWithBaseUrl(paidPlansApiBaseUrl);

  p.store = createStore(
    enableBatching(reducers),
    composeWithReduxDevTools(wixCodeApi)(
      applyMiddleware(
        createThrottleMiddleware({
          sockets: {
            invokeAsBatch: true,
            debounce: 5000,
            options: {
              maxWait: 10000,
              trailing: true,
            },
          },
        }),
        thunk.withExtraArgument({
          request,
          badgesRequest,
          membersGroupsRequest,
          oembedRequest,
          oembedRequestRCE,
          siteMembersRequest,
          paidPlansRequest,
          wixCodeApi,
          platformAPIs,
          compId,
          appParams,
          fedopsLogger,
          getRouter,
          sentry,
        }),
        globalLoaderMiddleware(),
        createRefreshAfterOnlineMigrationMiddleware(),
        createFastFormSubmitMiddleware({}),
        createRouterMiddleware(getRouter),
        createModalClosedMiddleware({ modalResolverMapByType, createShowMessage }),
        createModalOpenedMiddleware({ modalDataLoaderByType }),
        createMessageMiddleware({ resolvers: messageResolvers }),
        createInteractionsMiddleware(fedopsLogger, {
          [CREATE_POST_SUCCESS]: CREATE_POST,
          [UPDATE_POST_SUCCESS]: UPDATE_POST,
          [INCREMENT_POST_LIKE_COUNT_SUCCESS]: LIKE_POST,
          [DELETE_POST_SUCCESS]: DELETE_POST,
          [CREATE_COMMENT_SUCCESS]: CREATE_COMMENT,
          [UPDATE_COMMENT_SUCCESS]: UPDATE_COMMENT,
          [INCREMENT_COMMENT_LIKE_COUNT_SUCCESS]: LIKE_COMMENT,
          [DELETE_COMMENT_SUCCESS]: DELETE_COMMENT,
        }),
        createLocalStorageMiddleware(platformAPIs),
        createBiMiddleware(forumsUouEvents),
        createBiMiddleware(forumsUsersEvents),
        isSSR || !isSite
          ? noopMiddleware
          : initMiddlewareForExperiment({
              delayedActionRegExp: /duplexer/,
              experiment: 'specs.wixForum.DuplexerHttpStreaming',
              initMiddleware: isEnabled =>
                createDuplexerMiddleware({
                  duplexerConnection: createDuplexerConnection({
                    duplexerSocketsServerUrl: 'duplexer.wix.com',
                    appDefId: appParams.appDefinitionId,
                    getInstance,
                    useHttpStreaming: isEnabled,
                  }),
                  eventHandlers: socketEventHandlers,
                  onError: error => sentry.captureException(error),
                }),
            }),
        isEditor || isPreview ? forumDisplayStateMiddleware() : noopMiddleware,
      ),
    ),
  );

  return p.store;
}

export function initializeActions({
  wixCodeApi,
  store,
  fedopsLogger,
  fedopsAppLoaded,
  BiLocationTracker,
  sentry,
}) {
  return {
    interactionStarted: interaction => fedopsLogger.interactionStarted(interaction),
    interactionEnded: interaction => fedopsLogger.interactionEnded(interaction),
    startPrivateChat: uid =>
      wixCodeApi.site.getPublicAPI(CHAT_APP_DEF_ID).then(api => api.startPrivateChat(uid)),
    navigateToSection: section =>
      wixCodeApi.site
        .getPublicAPI(SANTA_MEMBERS_APP_ID)
        .then(api => api.navigateToSection(section)),
    navigateToMember: ({ memberId, memberSlug }) =>
      wixCodeApi.site
        .getPublicAPI(SANTA_MEMBERS_APP_ID)
        .then(api => api.navigateToMember({ memberId, memberSlug })),
    logout: () => wixCodeApi.user.logout(),
    navigateToRoot: () => {
      wixCodeApi.location.to('/');
    },
    navigateToProfile: ({ memberId, memberSlug }) => {
      wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then(api => {
        api.navigateToMember({ memberId, memberSlug }).catch(console.error);
      });
    },
    navigateToProfileSettings: () => {
      wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then(api => {
        const user = getCurrentUser(store.getState());
        api
          .navigateToSection({
            appDefinitionId: '14f25dc5-6af3-5420-9568-f9c5ed98c9b1',
            sectionId: 'settings',
            memberId: user.siteMemberId,
            memberSlug: user.slug,
          })
          .catch(console.error);
      });
    },
    navigateToNotifications: () => {
      wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then(api => {
        api
          .navigateToSection({
            appDefinitionId: '14f25924-5664-31b2-9568-f9c5ed98c9b1',
            sectionId: 'notifications',
          })
          .catch(console.error);
      });
    },
    renderSEOTags: data => wixCodeApi.seo.renderSEOTags(data),
    ...decorateActionsWithLogger(
      bindActionCreators(
        {
          appLoaded: once(appLoaded(fedopsAppLoaded)),
          addBestAnswer: addBestAnswerAndNotify,
          removeBestAnswer: removeBestAnswerAndNotify,
          showJoinCommunityPopup,
          search,
          incrementCommentLikeCount,
          incrementPostLikeCount,
          pageOpened,
          navigateWithinForum,
          navigateToPaidPlans,
          uploadImageCategoriesManager,
          fetchPostPageData,
          incrementPostViewCount,
          setIsLoaded,
          setIsLoading,
          fetchCategoryPosts,
          fetchSubcategories,
          subscribeToCategory,
          unsubscribeFromCategory,
          subscribeToPost,
          unsubscribeFromPost,
          subscribeToAllCategories,
          unsubscribeFromAllCategories,
          pinPost,
          unpinPost,
          enablePostComments,
          disablePostComments,
          actionsOpened,
          actionsClosed,
          buttonClicked,
          removeItemFromUploadedRegistry,
          fetchOembed,
          createComment,
          createCommentReply,
          changePage,
          setPostsPageSorting,
          fetchReplies,
          fetchInitialReplies,
          startEditingComment,
          stopEditingComment,
          fetchCommentsWithReplies,
          fetchComment,
          clearComments,
          goBack,
          emitOpenCategory,
          emitCloseCategory,
          emitTypingThrottled,
          clearTypingUsers,
          emitClosePost,
          emitOpenPost,
          fastFormSubmitCallback,
          setSearchPageSorting,
          setHasReportedAppLoaded,
          fileDownloadRequest,
          fileUploaded,
          userEventsMakeCategoryChildOrParent,
          userEventsShowEmptyState,
          userEventsReplyOrCommentIntent,
          userEventsClickPublish,
          userEventsClickLike,
          userEventsClickProfileLink,
          userEventsHitUploadSizeLimit,
          userEventsClickCreatePost,
          userEventsClickCreatePostButton,
          requestLogin,
          setSavedAction,
          ...categoriesManagerApi,
          ...modalActions,
          ...messageActions,
          manageCategoryMembersRemoveSiteMembers,
          manageCategoryMembersAddSiteMembers,
          assignMembersToGroup,
          deleteMembersFromGroup,
          fetchSiteMembersList,
          resetSiteMembersList,
          fetchMembersGroupsList,
          fetchBadges,
          fetchPaidPlans,
          categoryAddGroups,
          categoryRemoveGroups,
          setPostTypeFilter,
          resetPostTypeFilter,
          clearSearchResults,
          upvoteComment,
          downvoteComment,
          unvoteComment,
          triggerOfflineMigration,
          fetchCategoriesGroups,
          fetchSimilarPosts,
          resetLikesData,
          markPostsAsRead,
          fetchExternalLinks: fetchExternalLinks(onFetchExternalLinksHook),
          fetchLocalStorage,
          setLocalStorageItem,
        },
        store.dispatch,
      ),
      sentry,
    ),
    setBiLocation: BiLocationTracker.set,
  };
}

export function initializePromisifiedActions({ wixCodeApi, store, sentry }) {
  return {
    ...decorateActionsWithLogger(
      bindActionCreators(
        {
          createCommentPromisified: createCommentPromisified(onBeforeCommentCreateHook),
          createCommentReplyPromisified: createCommentReplyPromisified(onBeforeCommentCreateHook),
          fetchRepliesToPagePromisified,
          resolveCommentDeepLinkUrlPromisified,
          createPostAndNavigateToItPromisified: createPostAndNavigateToItPromisified(
            onBeforePostCreateHook,
          ),
          updateCommentPromisified: updateCommentPromisified(onBeforeCommentUpdateHook),
          updatePostPromisified: updatePostPromisified(onBeforePostUpdateHook),
          requestCredentialsPromisified,
          requestCredentialsV2Promisified,
          requestFileDownloadUrlPromisified,
          requestLoginPromisified,
          fetchMembersGroupsListPromisified,
          categoriesManagerPersistCategoryPromisified,
          fetchRepliesPromisified,
          fetchLikesDataPromisified,
          waitForSuccessfulResponsePromisified,
          uploadImageRCEPromisified,
          uploadImageCategoriesManagerPromisified,
        },
        store.dispatch,
      ),
      sentry,
    ),
  };
}

function initializeStoreBaseData({ wixCodeApi, store, language, platformAPIs, config }) {
  const translationsPromise = store.dispatch(fetchTranslations(language));
  const promises = [
    initUserDependentStoreBaseData({ store, wixCodeApi, translationsPromise }),
    isMaInstalled(wixCodeApi).then(isInstalled => {
      if (isInstalled) {
        store.dispatch(initializeMaNavigation());
        store.dispatch(initIsMembersChatEnabled());
      }
    }),
    store.dispatch(setCommunitiesContext()),
    store.dispatch(setBasicParams({ language, pageNumber: platformAPIs.bi.pageNumber })),
    store.dispatch(setStyleParams(config.style.styleParams)),
    store.dispatch(fetchLocalStorage()),
    store.dispatch(emitForumOpened()),
    store.dispatch(setCustomRoutes()),
  ];
  return Promise.all(promises);
}

function initUser({ store, wixCodeApi }) {
  const user = wixCodeApi.user.currentUser;
  const { biToken } = parseInstance(getCurrentInstance(wixCodeApi));
  const isSiteSaved = biToken !== undefined;
  return user.loggedIn && user.id && isSiteSaved ? store.dispatch(fetchUser()) : Promise.resolve();
}

function initUserDependentStoreBaseData({
  store,
  wixCodeApi,
  translationsPromise = Promise.resolve(),
}) {
  const { biToken } = parseInstance(getCurrentInstance(wixCodeApi));
  const isSiteSaved = biToken !== undefined;

  return Promise.all([
    initUser({ store, wixCodeApi }),
    store.dispatch(fetchForumData()),
    store.dispatch(fetchAllCategories()),
    isSiteSaved ? store.dispatch(fetchBadges()) : Promise.resolve(),
    isSiteSaved
      ? store
          .dispatch(fetchMembersGroupsAndPaidPlans())
          .then(
            () =>
              !isSSR(store.getState()) && store.dispatch(showActivateSubscriptionMessageIfNeeded()),
          )
          .catch(() => console.warn('Paid plans initial data fetch failed')) // don't crash the app in case paid plan fetch fails
      : Promise.resolve(),
    translationsPromise.then(() => store.dispatch(fetchExperiments())),
    store.dispatch(initialiseInstanceValues(getCurrentInstance(wixCodeApi))),
  ]);
}

function refreshDataOnLogin({ wixCodeApi, store, router }) {
  wixCodeApi.user.onLogin(
    once(() => {
      setTimeout(() => {
        initUserDependentStoreBaseData({ store, wixCodeApi })
          .then(() => doRouting({ store, router, location: getLocation(store.getState()) }))
          .then(() => invokeSavedAction(store));
      });
    }),
  );
}

function doRouting({
  router,
  location,
  store,
  isInitialLoad = true,
  shouldTransformOldLink = true,
}) {
  return router
    .match(shouldTransformOldLink ? transformOldLinks(location).pathname : location.pathname)
    .then(match => isInitialLoad && store.dispatch(setRouterMatch(match)));
}

function transformOldLinks(location) {
  const pathname = decodeURIComponent(location.pathname);
  const pageQueryMatch = pathname.match(/page=([^&]*)&dl=([^&]*)/);
  const searchQueryMatch = pathname.match(/query=([^&]*)/);
  const searchQuery = location.query.query || (searchQueryMatch && searchQueryMatch[1]);
  if (location.pathname.match(/^\/search\/(?:posts|members)/) && searchQuery) {
    return makeNewLocation(location, `/search/${searchQuery}`);
  } else if (location.query.page) {
    return makeNewLocation(location, `${location.pathname}/p-${location.query.page}`);
  } else if (pageQueryMatch) {
    const url = pathname.split('?');
    return makeNewLocation(
      location,
      `${url[0]}/p-${pageQueryMatch[1]}${
        pageQueryMatch[2] ? `/dl-${pageQueryMatch[2].split('/').join('-')}` : ''
      }`,
    );
  }
  return location;
}

function makeNewLocation(location, pathname) {
  return {
    ...location,
    pathname,
  };
}

function filterStateForCache(state) {
  return {
    ...state,
    modal: { ...state.modal, openedModals: {} },
    appLoaded: { hasReported: false },
  };
}

function createLogger(isDebug, isProduction) {
  return (...args) => {
    if (!isProduction || isDebug) {
      console.log(...args);
    }
  };
}

function composeWithReduxDevTools() {
  return f => f;

  // if you need this, just reenable
  // const shouldEnable =
  //   wixCodeApi.location.query.debugStore && wixCodeApi.window.rendering.env !== 'backend';

  // return shouldEnable
  //   ? composeWithDevTools({
  //       name: 'fapp',
  //       realtime: shouldEnable,
  //       port: 8000,
  //       suppressConnectErrors: true,
  //     })
  //   : f => f;
}

function initializeNavigationHandlerForEditor({ store, router }) {
  if (typeof window !== 'undefined') {
    window.__navigateWithinForumInternally = pathname => {
      doRouting({
        store,
        router,
        location: { pathname },
        isInitialLoad: false,
        shouldTransformOldLink: false,
      });
    };
  }
}
